import axios from 'axios'

let baseApiUrl = 'https://viacep.com.br/ws/';

const httpCEP = axios.create({
  baseURL: baseApiUrl,
  headers: {
    Accept: 'text/html'
  }
});

export { httpCEP }