<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { http } from "../../../helpers/easyindustriaapi/config";
import Insert from "./insert";
import Edit from "./edit";
import Detail from "./detail";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
export default {
  page: {
    title: "Cadastro de Pessoas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Insert, Edit, Detail },
  data() {
    return {
      loader: {
        get: false,
      },
      currentUser: null,
      currentEmpresa: null,
      submitted: false,
      pessoas: [],
      currentPessoa: null,
      titleBody: "Listagem de Pessoas",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Pessoas",
          href: "/pessoas",
        },
        {
          text: "Listagem",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          label: "ID",
          key: "id",
          sortable: true,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Nome",
          key: "pes_apelido",
          sortable: true,
          thClass: "text-center",
        },
        {
          label: "E-mail",
          key: "pes_email",
          sortable: true,
          thClass: "text-center",
        },
        {
          label: "Telefone",
          key: "pes_telefone",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Tipo Pesssoa",
          key: "pes_fornecedor",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      editando: false,
      inserindo: false,
      hideSearches: false,
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.notification.clear;
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.pessoas.length;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    clonefoot() {
      return this.pessoas.length > 10 ? true : false;
    },
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  mounted() {
    this.getData();
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    /**
     * Search the table data with search input
     */
    gerar_pdf() {
      this.makeToast("sucess", "Gerando PDF");
      let tamanho = this.pessoas.length;
      const doc = new jsPDF();
      const titulo = "Lista de clientes";
      const header = ["Nome", "Razão", "CNPJ"];
      const footer = ["Total Geral", `${tamanho}`, ""];
      // Adicionar o header e o foote
      doc.text(titulo, 80, 10);
      // Array para armazenar as linhas da tabela
      const linhasDaTabela = [];
      // Preencher o array com linhas para cada objeto
      this.pessoas.forEach(function (objeto) {
        if (objeto.pes_cliente == 0) {
          const linha = [
            objeto.pes_apelido,
            objeto.pes_cidade,
            objeto.pes_cpfcnpj,
          ];
          linhasDaTabela.push(linha);
        }
        if (objeto.pes_fornecedor == -2) {
          const linha = [
            objeto.pes_apelido,
            objeto.pes_cidade,
            objeto.pes_cpfcnpj,
          ];
          linhasDaTabela.push(linha);
        }
        if (objeto.pes_transportadorar == -3) {
          const linha = [
            objeto.pes_apelido,
            objeto.pes_cidade,
            objeto.pes_cpfcnpj,
          ];
          linhasDaTabela.push(linha);
        }
        if (objeto.pes_contador == -4) {
          const linha = [
            objeto.pes_apelido,
            objeto.pes_cidade,
            objeto.pes_cpfcnpj,
          ];
          linhasDaTabela.push(linha);
        }
        if (objeto.pes_funcionario == -5) {
          const linha = [
            objeto.pes_apelido,
            objeto.pes_cidade,
            objeto.pes_cpfcnpj,
          ];
          linhasDaTabela.push(linha);
        }
        let linha = "";
        linhasDaTabela.push(linha);
      });
      doc.autoTable({
        head: [header],
        body: linhasDaTabela,
        foot: [footer],
      });
      // this.clientes.forEach(function (dado) {
      //     doc.autoTable({
      //         body: [dado],
      //     });
      // });
      // Salvar o documento
      doc.save("relatorio.pdf");
    },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    setPessoa(pessoa) {
      this.currentPessoa = pessoa;
      console.log("estou deletando")
      console.log(this.currentPessoa)
    },
    editar(pessoa) {
      this.currentPessoa = pessoa;
      this.editando = !this.editando;
    },
    inserir() {
      this.inserindo = !this.inserindo;
    },
    async doPost(pessoa) {
      console.log(pessoa)
      console.log(this.currentEmpresa)
      //this.currentEmpresa
      try {
        this.loader.get = true;
        pessoa.pes_filialid = this.currentEmpresa.id;

        let response = await http
          .post("/pessoa?empresa_id=" + pessoa.pes_filialid, pessoa)
          .catch((error) => {
            console.log(error);
            this.makeToast("danger", error.response);
            this.loader.get = false;
          });

        if (response.status === 200) {
          this.getData();
          this.back();
          this.loader.get = false;
          this.makeToast("success", "Registro incluído");
        }
      } catch (error) {
        console.log(error);
        this.makeToast("danger", error.response);
        this.loader.get = false;
      }
    },
    async doPut(pessoa) {
     // console.log(this.currentEmpresa)
      try {
        this.loader.get = true;

        let response = await http
          .put(
            "/pessoa/" + pessoa.id + "?empresa_id=" + this.currentEmpresa.id,
            pessoa
          )
          .catch((error) => {
            this.makeToast("danger", error.response);
            this.loader.get = false;
          });

        if (response.status === 200) {
          this.getData();
          this.back();
          this.makeToast("success", "Registro incluído");
          this.loader.get = false;
        }
      } catch (error) {
        this.makeToast("danger", error.response);
        this.loader.get = false;
      }
    },
    async doExcluir() {
      console.log("estou no do excluir")
      this.excluir(this.currentPessoa);
    },
    async excluir(pessoa) {
      try {
        this.loader.get = true;

        let response = await http
          .delete(
            "/pessoa/" + pessoa.id + "?empresa_id=" + this.currentEmpresa.id,
            pessoa
          ).catch((error) => {
            this.loader.get = false;

            if (error.response && error.response.status === 403) {
              this.error("Usuário não tem permissão!");
            } else {
              this.makeToast("danger", error.response);
            }
          });

        if (response.status === 200) {
          this.getData();
          this.loader.get = false;
          this.makeToast("warning", "Registro excluído");
        }
      } catch (error) {
        this.loader.get = false;
        this.makeToast("danger", error.response);
      }
    },
    back() {
      this.inserindo = false;
      this.editando = false;
    },
    async getData() {
      try {
        this.loader.get = true;
        let response = await http
          .get("/pessoa?empresa_id=" + this.currentEmpresa.id)
          .catch((error) => {
            console.log(error);
            this.makeToast("danger", error.response);
            this.offLoader();
          });
        if (response.status === 200) {
          this.pessoas = response.data ? response.data : null;
          console.log(this.pessoas);
          this.loader.get = false;
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
        this.loader.get = false;
      }
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <button v-if="isHide" class="btn btn-light" @click="searchInputs()">
          <span
            class="fa-sm fa"
            :class="hideSearches ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></span>
          Busca avançada
        </button>
      </div>
      <div class="col-md-12">
        <!-- Content-Top -->
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mt-3"
              v-if="notification.message"
              show
              dismissible
            >
              {{ notification.message }}
            </b-alert>
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button
                  v-if="isHide"
                  class="btn btn-success"
                  @click="inserir()"
                >
                  + Incluir Pessoa
                </button>
                <button
                  v-if="!isHide"
                  class="btn btn-secondary"
                  @click="back()"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="hideSearches">
            <!-- Start Card -->
            <div class="card border border-primary">
              <div class="card-body">
                <h4 class="card-title">Filtros</h4>
                <p class="card-title-desc">Informações do Cliente</p>
                <form class="needs-validation" name="search">
                  <div class="row">
                    <!-- <div class="col-md-3">
                      <div class="form-group">
                         <label for="searchCompras-status">Nº Documento</label> 
                        <div class="row">
                          <div class="col-md-6">
                            <input id="searchCompras-fromNNf"  type="number"
                              class="form-control" placeholder="Nº NF" /> 
                          </div>
                          <div class="col-md-6">
                            <input id="searchCompras-toNNf"  type="number"
                              class="form-control" placeholder="Nº NF" /> 
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-2 mt-4 ml-4">
                      <div class="card">
                        <label for="">Cliente</label>
                        <b-form-checkbox
                          id="produto-ativo1"
                          name="ativo"
                          value="-1"
                        >
                          001-Cliente
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-2 mt-4 ml-4">
                      <div class="card">
                        <label for="">Fornecedor</label>
                        <b-form-checkbox
                          id="produto-ativo2"
                          name="ativo"
                          value="-1"
                        >
                          002-Fornecedor
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-2 mt-4 ml-4">
                      <div class="card">
                        <label for="">Transportadora</label>
                        <b-form-checkbox
                          id="produto-ativo3"
                          name="ativo"
                          value="-1"
                        >
                          003-Transportadora
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-2 mt-4 ml-4">
                      <div class="card">
                        <label for="">Contador</label>
                        <b-form-checkbox
                          id="produto-ativo4"
                          name="ativo"
                          value="-1"
                        >
                          004-Contador
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-2 mt-4 ml-4">
                      <div class="card">
                        <label for="">Funcinario</label>
                        <b-form-checkbox
                          id="produto-ativo5"
                          name="ativo"
                          value="-1"
                        >
                          005-Funcinario
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-primary" @click="gerar_pdf()">
                    Gerar Relatorio
                  </button>
                </form>
              </div>
            </div>
            <!-- End Card -->
          </div>
        </div>
        <!-- End Content-Top -->
        <div class="card">
          <div class="card-body">
            <!-- Filters -->
            <div class="row mt-4" v-if="isHide">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Exibir&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Pesquisar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Pesquisar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- End Filters -->
            <!-- Table List -->
            <div v-if="isHide" class="table-responsive mb-0">
              <b-table
                :items="pessoas"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :hover="true"
                :foot-clone="clonefoot"
              >
                <template #cell(acoes)="row">
                  <!-- `data.value` is the value after formatted by the Formatter -->
                  <b-dropdown
                    v-bind:id="'dropdown-' + row.item.id"
                    class="m-md-2"
                  >
                    <template #button-content>
                      <i data-v-6289eca4="" class="bx ri-menu-line"></i>
                    </template>
                    <b-dropdown-item
                      v-b-modal.modal-pessoa
                      @click="setPessoa(row.item)"
                      ><i class="bx ri-file-search-line"></i>
                      Visualizar</b-dropdown-item
                    >
                    <b-dropdown-item @click="editar(row.item)"
                      ><i class="bx ri-edit-2-line"></i>
                      Alterar</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-b-modal.modal-confirm-dialog
                      @click="setPessoa(row.item)"
                      ><i class="bx ri-eraser-fill"></i>
                      Excluir</b-dropdown-item
                    >
                  </b-dropdown>
                </template>

                <template #cell(pes_fornecedor)="row">
                  <div>
                    <span>
                      <b-badge
                        data-name="Total"
                        class="field-status fonte"
                        :variant="
                          row.item.pes_cliente == -1
                            ? 'primary'
                            : row.item.pes_fornecedor == -2
                            ? 'info'
                            : row.item.pes_contador == -4 
                            ? 'danger'
                            : row.item.pes_transportador == -3
                            ? 'warning' 
                            : row.item.pes_funcionario == -5
                            ? 'secondary'
                            : row.item.pes_motorista == -6
                            ? 'success' : ''
                        "
                        >{{
                          row.item.pes_fornecedor == -2
                            ? "Fornecedor"
                            : row.item.pes_cliente == -1
                            ? "Cliente"
                            : row.item.pes_contador == -4
                            ? "Contador"
                            : row.item.pes_transportador == -3
                            ? "transportadora"
                            : row.item.pes_funcionario == -5
                            ? "funcionario"
                            : row.item.pes_motorista == -6
                            ? "motorista"
                            : "Desconhecido"
                        }}</b-badge
                      >
                    </span>
                  </div>
                </template>
              </b-table>
            </div>
            <!-- End Table List -->
            <!-- Start Pagination -->
            <div v-if="isHide" class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
              <div class="col-md-12 text-center" v-if="loader.get">
                <i class="fa fa-spinner fa-5x fa-spin text-success"></i>
              </div>
            </div>
            <!-- End Pagination -->
            <!-- Start Modal Confirm -->
            <b-modal
              no-close-on-backdrop
              id="modal-confirm-dialog"
              title="CONFIRME"
              title-class="font-18"
              @ok="doExcluir()"
            >
              <p>Deseja realmente excluir o registro?</p>
            </b-modal>
            <!-- End Modal Confirm -->
            <!-- Start Modal Pessoa -->
            <b-modal
              size="xl"
              hide-footer
              id="modal-pessoa"
              :title="currentPessoa ? currentPessoa.pes_apelido : 'Pessoa'"
              title-class="font-18"
            >
              <Detail :pessoa="currentPessoa" />
            </b-modal>
            <!-- End Modal Pessoa -->
          </div>
          <div class="card-body" v-if="!isHide">
            <!-- Form Edit -->
            <Edit
              v-if="editando"
              :currentEmpresa="currentEmpresa"
              :pessoa="currentPessoa"
              @doPut="doPut"
              @newTitle="changeHomeTitle"
              @back="back"
            />
            <!-- End Form Edit -->
            <!-- Start Form Insert -->
            <Insert
              v-if="inserindo"
              :currentEmpresa="currentEmpresa"
              @gravar="doPost"
              @newTitle="changeHomeTitle"
              @back="back"
            />
            <!-- Start Form Insert -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.fonte {
  color: white;
  font-weight: bold;
}
</style>