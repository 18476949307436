<script>
export default {
  props: {
      pessoa: {
          type: Object,
      },
  },
  data () {
      return {
        loader: {
            get: false
        },
        legendCPFCNPJ: 'CPF/CNPJ',
        legendNome: 'Nome/Razão social',
        listCidades: [],
        listUFs: [],
        listPaises: [],
    }
  },
  methods: {      
    voltar() {
        this.editando = false;
        this.$emit('status', this.pessoa);
        this.$emit('voltar');
    },
  },
}
</script>

<template>
    <div id="pessoa">
        <div class="row flex-nowrap align-items-center px-4 pt-3 pb-2">
            <div class="col p-0" style="min-width: 0px;">
                <div class="d-flex align-items-center w-100">
                    <button class="btn btn-link"><i @click="$bvModal.hide('modal-pessoa')" title="Voltar" class="fas fa-arrow-left mr-3"></i></button>
                    <i title="Pessoa" class="mb-1 mr-1 fas fa-hospital-user h5"></i> 
                    {{ pessoa.pes_razao }}
                </div>
            </div>
            <div class="ml-3">
                <div class="d-flex pr-3 justify-content-end" style="opacity: 1;">
                    <div class="recordProgressionContainer">
                        <div class="btn-group">
                            <button v-if="!(parseInt(pessoa.pes_cliente) === 0)" title="É Cliente" class="mw-160px btn btn-light height-36px d-flex btn align-items-center pl-3 py-0 bg-grey-hue-9 rounded pr-3">
                                <i aria-hidden="true" class="fa fa-stream mr-1"></i>
                                <div class="text-truncate">Cliente</div>
                            </button>
                            <button v-if="!(parseInt(pessoa.pes_fornecedor) === 0)" title="É Fornecedor" class="mw-160px btn btn-light height-36px d-flex btn align-items-center pl-3 py-0 bg-grey-hue-9 rounded pr-3">
                                <i aria-hidden="true" class="fa fa-stream mr-1"></i>
                                <div class="text-truncate">Fornecedor</div>
                            </button>
                            <button v-if="!(parseInt(pessoa.pes_funcionario) === 0)" title="É Funcionário" class="mw-160px btn btn-light height-36px d-flex btn align-items-center pl-3 py-0 bg-grey-hue-9 rounded pr-3">
                                <i aria-hidden="true" class="fa fa-stream mr-1"></i>
                                <div class="text-truncate">Funcionário</div>
                            </button>
                            <button v-if="!(parseInt(pessoa.pes_transportador) === 0)" title="É Transportador" class="mw-160px btn btn-light height-36px d-flex btn align-items-center pl-3 py-0 bg-grey-hue-9 rounded pr-3">
                                <i aria-hidden="true" class="fa fa-stream mr-1"></i>
                                <div class="text-truncate">Transportador</div>
                            </button>
                            <button v-if="!(parseInt(pessoa.pes_contador) === 0)" title="É Contador" class="mw-160px btn btn-light height-36px d-flex btn align-items-center pl-3 py-0 bg-grey-hue-9 rounded pr-3">
                                <i aria-hidden="true" class="fa fa-stream mr-1"></i>
                                <div class="text-truncate">Contador</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid">
                <div class="colp-0 d-flex flex-row align-items-center flex-wrap">
                    <div class="pr-3" style="min-width: 0px;">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_pessoa_id">Código<span class="text-warning mr-1">*</span></label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <span data-name="assigned_pessoa_id" class="field-id">
                                        {{ pessoa.id }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mr-14px border-right border-grey-8 mb-2">
                            <div class="mw-190px min-w-190">
                                <div class="d-flex flex-column mw-col-12">
                                    <label for="assigned_pes_cpfcnpj">CPF/CNPJ<span class="text-warning mr-1">*</span></label>
                                </div>
                            </div>
                            <div class="d-flex align-items-center h-20 composite-value mw-col-10">
                                <span data-name="pes_cpfcnpj" v-bind:title="pessoa.pes_cpfcnpj ? pessoa.pes_cpfcnpj : 'Não configurado'" class="text-break text-truncate text-lighter-grey">
                                    {{ pessoa.pes_cpfcnpj ? pessoa.pes_cpfcnpj : 'Não configurado' }}
                                </span> 
                            </div>
                    </div>
                    <div class="pr-3" style="min-width: 0px;">
                            <div class="mw-190px min-w-190">
                                <div class="d-flex flex-column mw-col-12">
                                    <label for="assigned_pes_email">E-mail</label>
                                </div>
                            </div>
                            <div class="d-flex align-items-center h-20 composite-value mw-col-10">
                                <span data-name="email" 
                                 v-bind:title="pessoa.pes_email ? pessoa.pes_email : 'Não configurado'" class="text-break text-truncate text-lighter-grey">
                                    {{ pessoa.pes_email ? pessoa.pes_email : 'Não configurado' }}
                                </span> 
                            <!---->
                            </div>
                    </div>
                    <div class="mr-14px border-right border-grey-8 mb-2">
                            <div class="mw-190px min-w-190">
                                <div class="d-flex flex-column mw-col-12">
                                    <label title="Identificador" name="assigned_pessoa_id">Telefone</label>
                                </div>
                            </div>
                            <div class="d-flex align-items-center h-20 composite-value mw-col-10">
                                <span data-name="assigned_pessoa_id" class="field-id">
                                    {{ pessoa.pes_fone ? pessoa.pes_fone : 'Não configurado' }}
                                </span>
                            </div>
                    </div>
                </div>

            </div>
            <div></div>
            <div class="container-fluid">
                <div class="colp-0 d-flex flex-row align-items-center flex-wrap">
                    <div class="pr-3" style="min-width: 0px;">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_pessoa_ie">I. E.</label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <span data-name="assigned_pes_ie" class="field-pes_ie">
                                        {{ pessoa.pes_ie ? pessoa.pes_ie : 'Não configurado' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mr-14px border-right border-grey-8 mb-2">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_pessoa_created_at">Criado em</label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <span data-name="assigned_created_at" class="field-created_at">
                                        {{ pessoa.created_at ? pessoa.created_at : 'Não configurado' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pr-3" style="min-width: 0px;">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_pessoa_updated_at">Última alteração</label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <span data-name="assigned_updated_at" class="field-updated_at">
                                        {{ pessoa.updated_at ? pessoa.updated_at : 'Não configurado' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mr-14px border-right border-grey-8 mb-2">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Ativo/Inativo" name="assigned_pessoa_ativo">Situação</label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <b-badge data-name="assigned_ativo" class="field-ativo" v-bind:variant="parseInt(pessoa.ativo) === -1 ? 'success' : 'danger'">
                                        {{ pessoa.ativo = -1 ? 'Ativo' : 'Inativo' }}
                                    </b-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mb-4">
                <div class="p-3-5 bg-white pessoa-endereco list-no-overflow c-text">
                    <div class="bg-white c-pointer text-grey-4">
                        <span><span class="text-warning mr-1">*</span> Endereço: <strong>{{ pessoa.pes_endereco }}, {{ pessoa.pes_numero }}{{ pessoa.pes_complemento ? ' - ' + pessoa.pes_complemento : ' '}} 
                            {{ pessoa.pes_bairro }} - {{ pessoa.pes_cidade }} - {{ pessoa.pes_uf }} - {{ pessoa.pes_pais }}</strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>