export const listEstados = [
    {id: 12, nome: "Acre", uf: "AC", pais: 1058},
    {id: 27, nome: "Alagoas", uf: "AL", pais: 1058},
    {id: 16, nome: "Amapá", uf: "AP", pais: 1058},
    {id: 13, nome: "Amazonas", uf: "AM", pais: 1058},
    {id: 29, nome: "Bahia", uf: "BA", pais: 1058},
    {id: 23, nome: "Ceará", uf: "CE", pais: 1058},
    {id: 53, nome: "Distrito Federal", uf: "DF", pais: 1058},
    {id: 32, nome: "Espírito Santo", uf: "ES", pais: 1058},
    {id: 52, nome: "Goiás", uf: "GO", pais: 1058},
    {id: 21, nome: "Maranhão", uf: "MA", pais: 1058},
    {id: 51, nome: "Mato Grosso", uf: "MG", pais: 1058},
    {id: 50, nome: "Mato Grosso do Sul", uf: "MS", pais: 1058},
    {id: 31, nome: "Minas Gerais", uf: "MG", pais: 1058},
    {id: 15, nome: "Pará", uf: "PA", pais: 1058},
    {id: 25, nome: "Paraíba", uf: "PB", pais: 1058},
    {id: 41, nome: "Paraná", uf: "PR", pais: 1058},
    {id: 26, nome: "Pernambuco", uf: "PE", pais: 1058},
    {id: 22, nome: "Piauí", uf: "PI", pais: 1058},
    {id: 33, nome: "Rio de Janeiro", uf: "RJ", pais: 1058},
    {id: 24, nome: "Rio Grande do Norte", uf: "RN", pais: 1058},
    {id: 43, nome: "Rio Grande do Sul", uf: "RS", pais: 1058},
    {id: 11, nome: "Rondônia", uf: "RO", pais: 1058},
    {id: 14, nome: "Roraima", uf: "RR", pais: 1058},
    {id: 42, nome: "Santa Catarina", uf: "SC", pais: 1058},
    {id: 35, nome: "São Paulo", uf: "SP", pais: 1058},
    {id: 28, nome: "Sergipe", uf: "SE", pais: 1058},
    {id: 17, nome: "Tocantins", uf: "TO", pais: 1058},
    {id: 99, nome: "Exterior", uf: "EX", pais: 1058},
];