<script>
console.clear();
import {http} from "@/helpers/easyindustriaapi/config";
import {httpCEP} from "@/helpers/easyindustriaapi/configViaCEP";
import {
  required,
  email,
} from "vuelidate/lib/validators";
import {listEstados} from "@/components/defaults/estados";
var tokens = {
  '#': {pattern: /\d/},
  'S': {pattern: /[a-zA-Z]/},
  'A': {pattern: /[0-9a-zA-Z]/},
  'U': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
  'L': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()}
};
export default {
  props: {
    pessoa: {
        type: Object,
    },
  },
  data() {
    return {
        loader: {
            get: false
        },
        editando: true,
        listCidades: [],
        listUFs: [],
        listPaises: [],
        paisDefault: {
            id: 1058,
            nome: "Brasil",
            created_at: null,
            updated_at: null
        },
        submitted: false,
    }
  },
  validations: {
    pessoa: {
        pes_cpfcnpj: { required },
        pes_apelido: { required },
        pes_razao: { required },
        pes_endereco: { required },
        pes_numero: { required },
        pes_bairro: { required },
        cidade_id: { required },
        estado_id: { required },
        pais_id: { required },
        pes_cep: { required },
        pes_email: { required, email },
        pes_filialid: { required },
        pes_indfinal: { required },
        pes_indiedest: { required },
    },
  },
  computed: {
      legendCPFCNPJ() {
          if ((this.pessoa) && (this.pessoa.pes_cpfcnpj)) {
              return (this.pessoa.pes_cpfcnpj.length > 11) ? 'CNPJ' : 'CPF';
        }

        return 'CPF/CNPJ';
    },
    legendNome() {
        if ((this.pessoa) && (this.pessoa.pes_cpfcnpj)) {
            return (this.pessoa.pes_cpfcnpj.length > 11) ? 'Razão social' : 'Nome';
        }

        return 'Nome/Razão social';
    },
    getCidadeId(objects, key) {
        return objects.map(function(object) { 
            return object[key];
        });
    },
    isCNPJ() {
      return this.legendCPFCNPJ === 'CNPJ';
    },
  },
  mounted() {
    this.$emit("newTitle", 'Alterar Pessoa');
    this.formatDocument(this.pessoa.pes_cpfcnpj);
    let pais_id   = this.pessoa.pais_id;
    var estado_id = this.pessoa.estado_id;
    let cidade_id = this.pessoa.cidade_id;
    this.listPaises = [this.paisDefault];
    this.listUFs = listEstados;
    this.getCidadesStart(this.pessoa.estado_id);
    this.pessoa.pais_id   = pais_id;
    this.pessoa.estado_id = estado_id;
    this.pessoa.cidade_id = cidade_id;
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
        this.counter++;
        this.$bvToast.toast(message, {
            title: `Notificação`,
            toaster: 'b-toaster-bottom-right',
            variant: variant,
            solid: true,
            appendToast: true
        });
    },
    applyMask (value, mask, masked = true) {
        value = value || "";
        var iMask = 0;
        var iValue = 0;
        var output = '';
        let cMask = '';
        let masker = '';
        let cValue = '';
        while (iMask < mask.length && iValue < value.length) {
            cMask = mask[iMask];
            masker = tokens[cMask];
            cValue = value[iValue];
            if (masker) {
                if (masker.pattern.test(cValue)) {
                    output += masker.transform ? masker.transform(cValue) : cValue;
                    iMask++;
                }
                    iValue++;
                } else {
                    if (masked) output += cMask;
                    if (cValue === cMask) iValue++;
                    iMask++;
                }
        }
        return output;
    },
    formatDocument(value) {
        value = typeof value != 'string' ? value.toString() : value;
        let document = '';
        let contString = value.replace(/\D/g,'');
        if (contString.length <= 11) {
            document = this.applyMask(value, '###.###.###-##', true);
        } else {
            document = this.applyMask(value, '##.###.###/####-##', true);
        }
        this.pessoa.pes_cpfcnpj = document;
    },
    maskFone() {
        let fone = this.pessoa.pes_telefone;
        fone = typeof fone != 'string' ? fone.toString() : fone;
        let contString = fone.replace(/\D/g,'');
        
        if (contString.length <=10) {
            return '(##) ####-####';
        } else {
            return '(##) # ####-####';
        }
    },
    clearEndereco () {
        this.pessoa.pes_endereco    = '';
        this.pessoa.pes_bairro      = '';
        this.pessoa.pes_complemento = '';
        this.pessoa.pes_cidade      = '';
        this.pessoa.pes_uf          = '';
    },
    async getCEP(value) {
        var cep = value.replace(/\D/g, '');
        this.loader.get = true;
        await httpCEP.get(cep + '/json')
        .then((response) => {
            this.meuCallbackCEP(response.data);
        })
        .finally(() =>{
            this.loader.get = false;
        })
        .catch((err)=> {
            console.log(err);
            this.loader.get = false;
        });
    },
    async getPaises() {
        let pais_id   = this.pessoa.pais_id;
        var estado_id = this.pessoa.estado_id;
        let cidade_id = this.pessoa.cidade_id;
        this.loader.get = true;
        await http.get('paises')
        .then((response) => {
            this.listPaises = response.data;
        })
        .finally(() => {
            this.pessoa.pais_id = pais_id;
            this.loader.get = false;
        })
        .catch((err)=> {
            console.log(err);
            this.loader.get = false;
        });
        if(this.pessoa.pais_id != '') {
            await http.get('pais/'+this.pessoa.pais_id+'/estados')
                .then((response) => {
                    this.listUFs = response.data;
                })
                .finally(() => {
                    this.pessoa.estado_id = estado_id;
                    this.loader.get = false;
                })
                .catch((err)=> {
                    console.log(err);
                    this.loader.get = false;
                });
            if (this.pessoa.estado_id != '') {
                await http.get('pais/'+this.pessoa.pais_id+'/estado/'+this.pessoa.estado_id+'/cidades')
                    .then((response) => {
                        this.listCidades = response.data;
                    })
                    .finally(() => {
                        this.pessoa.cidade_id = cidade_id;
                        this.loader.get = false;
                    })
                    .catch((err)=> {
                        console.log(err);
                        this.loader.get = false;
                    });
            }
        }
        this.loader.get = false;
    },
    async getUFs(event) {
        if (this.pessoa.pais_id != 0) {
            this.loader.get = true;
            await http.get('pais/'+event.target.value+'/estados')
                .then((response) => {
                    this.listUFs = response.data;
                })
                .finally(()=> {
                    this.loader.get = false;
                })
                .catch((err)=> {
                    console.log(err);
                    this.loader.get = false;
                });
        }
        this.pessoa.pais_id = event.target.value;
        this.loader.get = false;
    },
    async getCidadesStart(estadoId) {
        this.loader.get = true;
        if (this.listCidades && this.listCidades.length > 0 ) {
            return true;
        }

        await http.get('pais/'+this.pessoa.pais_id+'/estado/'+estadoId+'/cidades')
        .then((response) => {
            this.listCidades = response.data;
        })
        .finally(() => {
            this.loader.get = false;
        })
        .catch((err)=> {
            console.log(err);
            this.loader.get = false;
        });
        this.pessoa.estado_id = estadoId;
    },
    async getCidades(event) {
        this.loader.get = true;
        await http.get('pais/'+this.pessoa.pais_id+'/estado/'+event.target.value+'/cidades')
        .then((response) => {
            this.listCidades = response.data;
        })
        .finally(() => {
            this.loader.get = false;
        })
        .catch((err)=> {
            console.log(err);
            this.loader.get = false;
        });
        this.pessoa.estado_id = event.target.value;
    },
    setCidade(event) {
        this.pessoa.cidade_id = event.target.value;
    },
    meuCallbackCEP(conteudo) {
        if (!("erro" in conteudo)) {
            this.clearEndereco();
            this.pessoa.pes_endereco    = conteudo.logradouro;
            this.pessoa.pes_bairro      = conteudo.bairro;
            this.pessoa.pes_complemento = conteudo.complemento;
            this.pessoa.pes_cidade      = conteudo.localidade;
            // this.pessoa.cidade_id       = this.getCidadeId(this.listCidades);
            this.pessoa.pes_uf          = conteudo.uf;
        } else {
            //CEP não Encontrado.
            this.clearEndereco();
            alert("CEP não encontrado.");
        }
    },
    back() {
      this.$emit('back');
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.pessoa.$error) {
        this.makeToast('danger', 'Erro de validação!');
      } else {
        this.setPut();
      }
    },
    setPut() {
        console.log("esotu no put pessoas")
        console.log(this.pessoa)
        this.$emit('doPut', this.pessoa);
    },
    async setDataFromCnpj(data) {
        if (data) {
            this.pessoa.pes_razao = data.razao;
            this.pessoa.pes_fantasia = data.fantasia;
            this.pessoa.pes_cep = data.cep;
            this.pessoa.pes_endereco = data.endereco;
            this.pessoa.pes_numero = data.numero;
            this.pessoa.pes_bairro = data.bairro;
            this.pessoa.pes_complemento = data.complemento;
            
            const selectedCountry = this.listPaises.filter(pais => pais.nome === this.paisDefault.nome)[0];
            this.pessoa.pais_id = selectedCountry.id; 
            this.pessoa.pes_pais = selectedCountry.nome; 

            const selectedState = this.listUFs.filter(obj => obj.uf === data.uf)[0];
            this.pessoa.estado_id = selectedState.id;
            this.pessoa.pes_uf = selectedState.nome;

            await this.getCidadesStart(this.pessoa.estado_id);
            const selectedCity = this.listCidades.filter(function (city) {
                    let str = city.nome;
                    str = str.replace(/[ÀÁÂÃÄÅ]/g,"A");
                    str = str.replace(/[àáâãäå]/g,"a");
                    str = str.replace(/[ÈÉÊË]/g,"E");
                    return str.toLowerCase() === data.cidade.toLowerCase();
                }
            )[0];
            this.pessoa.cidade_id = selectedCity.id;
            this.pessoa.pes_cidade = selectedCity.nome;
        }
    }
  },
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Alterar pessoa {{ pessoa.pes_apelido }}</h4><BR />
            <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success"></i>
            <div v-if="!loader.get" class="row">
                <div class="col-md-12">
                    <div class="row mb-3">
                        <label for="pes_cpfcnpj" class="col-sm-2 col-form-label">{{ legendCPFCNPJ }}</label>
                        <div class="col-md-4 col-sm-12">
                            <input disabled v-model="pessoa.pes_cpfcnpj" class="form-control" type="text" placeholder="CPF/CNPJ" id="pes_cpfcnpj"
                             :class="{ 'is-invalid': submitted && $v.pessoa.pes_cpfcnpj.$error }"
                            >
                            <div v-if="submitted && $v.pessoa.pes_cpfcnpj.$error" class="invalid-feedback">
                                <span v-if="!$v.pessoa.pes_cpfcnpj.required"> {{ $t('mesages.validation.required') }}</span>
                            </div>
                        </div>
                        <label for="pes_apelido" class="col-md-2 col-sm-12 col-form-label">Apelido</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_apelido" class="form-control" type="text" placeholder="Apelido do Cliente" id="pes_apelido"
                             :class="{ 'is-invalid': submitted && $v.pessoa.pes_apelido.$error }"
                            >
                            <div v-if="submitted && $v.pessoa.pes_apelido.$error" class="invalid-feedback">
                                <span v-if="!$v.pessoa.pes_apelido.required"> {{ $t('mesages.validation.required') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="pes_razao" class="col-md-2 col-sm-12 col-form-label">{{ legendNome }}</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_razao" class="form-control" type="text" placeholder="Razão social do Cliente" id="pes_razao"
                             :class="{ 'is-invalid': submitted && $v.pessoa.pes_razao.$error }"
                            >
                            <div v-if="submitted && $v.pessoa.pes_razao.$error" class="invalid-feedback">
                                <span v-if="!$v.pessoa.pes_razao.required"> {{ $t('mesages.validation.required') }}</span>
                            </div>
                        </div>
                        <label v-if="isCNPJ" for="pes_fantasia" class="col-md-2 col-form-label">Nome Fantasia</label>
                        <div v-if="isCNPJ" class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_fantasia" class="form-control" type="text" placeholder="Nome fantasia do Cliente" id="pes_fantasia"
                             :class="{ 'is-invalid': isCNPJ && submitted && $v.pessoa.pes_fantasia.$error }"
                            >
                            <div v-if="isCNPJ && submitted && $v.pessoa.pes_fantasia.$error" class="invalid-feedback">
                                <span v-if="isCNPJ && !$v.pessoa.pes_fantasia.required"> {{ $t('mesages.validation.required') }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="isCNPJ" class="row mb-3">
                        <label for="pes_ie" class="col-md-2 col-sm-12 col-form-label">Inscriçãoo Estadual</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_ie" class="form-control" type="text" placeholder="IE do Cliente" id="pes_ie" maxlength="14">
                        </div>
                        <label for="pes_im" class="col-md-2 col-form-label">Inscrição Municipal</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_im" class="form-control" type="text" placeholder="IM do Cliente" id="pes_im">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="pes_cep" class="col-md-2 col-form-label">CEP</label>
                        <div class="col-md-3 col-sm-10">
                            <input
                             v-model="pessoa.pes_cep"
                             v-mask="'#####-###'"
                             type="text"
                             class="form-control"
                             :class="{ 'is-invalid': submitted && $v.pessoa.pes_cep.$error }"
                            />
                            <div v-if="submitted && $v.pessoa.pes_cep.$error" class="invalid-feedback">
                                <span v-if="!$v.pessoa.pes_cep.required"> {{ $t('mesages.validation.required') }}</span>
                            </div>
                        </div>
                        <div class="col-md-1 col-sm-2">
                            <button type="button" class="btn btn-outline-primary" @click="getCEP(pessoa.pes_cep)">
                                <i class="bx ri-search-2-line"></i>
                            </button>
                        </div>
                        <label for="pes_endereco" class="col-sm-2 col-form-label">Endereço</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_endereco" class="form-control" type="text" placeholder="Rua/Logadouro/Avenida..." id="pes_endereco" maxlength="200"
                             :class="{ 'is-invalid': submitted && $v.pessoa.pes_endereco.$error }"
                            >
                            <div v-if="submitted && $v.pessoa.pes_endereco.$error" class="invalid-feedback">
                                <span v-if="!$v.pessoa.pes_endereco.required"> {{ $t('mesages.validation.required') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="pes_numero" class="col-md-2 col-sm-12 col-form-label">Número</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_numero" class="form-control" type="text" placeholder="SN, 001, 200" id="pes_numero" maxlength="60">
                        </div>
                        <label for="pes_bairro" class="col-md-2 col-form-label">Bairro</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_bairro" class="form-control" type="text" placeholder="Bairro do Cliente" id="pes_bairro">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="pes_complemento" class="col-md-2 col-sm-12 col-form-label">Complemento</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_complemento" class="form-control" type="text" placeholder="Apartamento/KM/Quadra" id="pes_complemento" maxlength="60">
                        </div>
                        <label for="pes_pais" class="col-md-2 col-form-label">País</label>
                        <div class="col-md-4 col-sm-12">
                            <select @change="getUFs($event)" v-model="pessoa.pais_id"
                              class="form-control"
                              id="pes_pais"
                            >
                                <option value="0">Selecione o País</option>
                                <option v-for="pais in listPaises" :key="pais.id" :value="pais.id" v-bind:selected="(pessoa.pais_id == pais.id) ? 'selected': ''">{{ pais.nome }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="pes_uf" class="col-md-2 col-form-label">UF</label>
                        <div class="col-md-4 col-sm-12">
                            <select @change="getCidades($event)"
                              class="form-control"
                              id="pes_uf"
                            >
                                <option value="0">Selecione o Estado</option>
                                <option v-for="uf in listUFs" :key="uf.id" :value="uf.id" v-bind:selected="(pessoa.estado_id == uf.id) ? 'selected': ''">{{ uf.nome }}</option>
                            </select>
                        </div>
                        <label for="pes_cidade" class="col-md-2 col-form-label">Cidade</label>
                        <div class="col-md-4 col-sm-12">
                            <select @change="setCidade($event)" v-model="pessoa.cidade_id"
                              class="form-control"
                              id="pes_cidade"
                            >
                                <option value="0">Selecione a Cidade</option>
                                <option v-for="cidade in listCidades" :key="cidade.id" :value="cidade.id" v-bind:selected="(pessoa.pes_cidade == cidade.id) ? 'selected': ''">{{ cidade.nome }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="pes_email" class="col-sm-2 col-form-label">E-mail</label>
                        <div class="col-md-4 col-sm-12">
                            <input v-model="pessoa.pes_email" class="form-control" type="text" placeholder="E-mail do Cliente" id="pes_email">
                        </div>
                        <label for="pes_telefone" class="col-sm-2 col-form-label">Telefone</label>
                        <div class="col-md-4 col-sm-12">
                            <input
                             v-model="pessoa.pes_telefone"
                             v-mask="maskFone()"
                             type="text"
                             class="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="col-md-12 mt-10">
                <b-button type="button" @click="back" class="m-md-2">Voltar</b-button>
                <b-button type="submit" @click="setPut()" class="m-md-2">Gravar</b-button>
            </div>
        </div>
    </div>
</template>